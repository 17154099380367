<template>
  <div class="creek-segment">
    <CreekShow :segment="segment" />
  </div>
</template>

<script>
export default {
  name: 'CreekSegment',
  data(){
    return {}
  },
  props: {
    segment: {
      type: Object
    }
  },
  methods: {

  }
}
</script>