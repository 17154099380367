<template>
  <div class="ShowsPage">
    <CreekSchedule
      :initial-weekday-number="initialWeekdayNumber" 
      :initial-page-name="initialPageName"
    />
  </div>
</template>

<script type="text/javascript">

import CreekSchedule from '@/lib/creek/components/Schedule';

export default {
  name: 'SchedulePage',
  components: {
    CreekShows,
  },
  data () {
    return {

      // Examples. 
      initialWeekdayNumber: null,
      initialPageName: null,

    }

  },
  computed: {

  },
  methods: {

  },
  created () {

  },
}

</script>
