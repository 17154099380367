<template>
  <div class="creek-schedule">
    <CreekSegmentsNav
      :initial-page="initialPage" 
    />
    <div 
      v-if="pageSelected == 'all'"
      class="creek-segments-all" 
    >
      <input 
        type="text" 
        v-model="findTerm" 
        placeholder="Find" 
      />
    </div>
    <div 
      v-if="segments" 
      class="creek-segments"
    >
      <CreekSegment
        :data-segment="segment" 
        v-for="(segment, index) in segments" 
        :key="'segment-'+index" 
      />
      <!-- TODO: KWMR: -->
      <!--
      <div class="segments-cartoon">
        <img :src="assets.cartoons.YDP" />
      </div>
      -->
    </div>
    <div 
      v-if="!segments" 
      class="creek-segments" 
    >
      <span class="lang.loading"></span>
    </div>
  </div>
</template>

<script type="text/javascript">

import lang from '../lang/eng'
import fecha from 'fecha'

import CreekSegment from '@/lib/creek/components/Segment'

// TODO: KWMR:
// Jaime Icons
// import Cartoon_Icon_YDP from "../../../assets/img/jaime-icons/Icon_YDP.png"

export default {

  name: 'CreekSegments',

  props: ['page-selected'],

  components: {
    CreekSegment,
  },

  data () {

    return {

      segments: null,

      assets: {

        // TODO: KWMR:
        // cartoons: {
        //   YDP: Cartoon_Icon_YDP
        // }
        
      },

    }

  },

  computed: {
    pageSelected(){
      this.$route.params.pageName
    },
    weekdayNumber (){
      if(this.$route.params.weekdayNumber) {
        return this.$route.params.weekdayNumber;
      }
      else{
        return this.getWeekdayNumberForToday()
      }
    },
    segmentsFiltered () {
      if(!this.segments) {
        return [];
      }
    } 
  },
  methods: {

    fetchData () {
      
      this.segments = this.Creek.get('timeline');
      
    },

    getWeekdayNumberForToday() {

      var weekdayNumber = parseInt(fecha.format(new Date(), 'd'));

      // HACK: Move Sunday number to 7 rather than 0.
      if(weekdayNumber === 0){
        weekdayNumber = 7;
      }

      return weekdayNumber;

    }

  },
  created () {
    this.fetchData();
  },
}

</script>
